import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

import api from '../../../api'
import { getEmailForResetPassword } from '../../../selectors'

import { Button, message } from 'antd'

const EmailNotification = () => {
  const email = useSelector(getEmailForResetPassword)

  const handleClick = () => {
    const data = {
      email
    }

    api.passwordRestoration(data)
      .then(() => {
        message.success('Link has been resend to your email', 5)
      })
      .catch(() => {
        message.error('You already asked password restoration recently', 5)
      })
  }
  
  return (
    <section className="email-notification">
      { !email && <Redirect to="/password-reset" /> }
      
      <h2 className="caption-primary">Check your email</h2>
      <p>
        An email with password restoration was sent to <b>{ email }</b>. If you don’t see it in your inbox,
        please check your spam folder.
      </p>
      <p>
        Didn't receive the email? 
        <Button 
          onClick={ handleClick }
          type="link" 
          className="link-primary"
        >
          Resend an email
        </Button>
      </p>
      <p>
        Entered the wrong email? <Link to="/password-reset" className="link-primary">Reenter your email</Link>
      </p>
    </section>
  )
}

export default EmailNotification
