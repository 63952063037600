import React, { useState, useEffect, useRef } from 'react'
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import api from '../../../api'
import { getIsAuth } from '../../../selectors'

import Reaptcha from 'reaptcha'
import { 
  Form, 
  Input, 
  Button,
  Row,
  Col,
  Checkbox
} from 'antd'
import { 
  UserOutlined, 
  LockOutlined,
  MailOutlined,
  BankOutlined 
} from '@ant-design/icons'

const reCaptchaKey = '6LfLAekUAAAAAD0jnB0l31NSMC37F9VnDz-N_14m'

const SignUp = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const emailInput = useRef()
  const history = useHistory()
  const location = useLocation()
  const [form] = Form.useForm()
  const isAuth = useSelector(getIsAuth)

  const prevLocation = location.state ? location.state.from : null 

  useEffect(() => {
    if (prevLocation === '/verification-email') {
      const { 
        password,
        organization,
        full_name,
        username
       } = location.state.userForm

      form.setFieldsValue({
        username,
        full_name,
        organization,
        password,
        confirmPassword: password
      })

      emailInput.current.focus()
    }
  }, [])

  const onVerify = () => {
    setIsDisabled(false)
  }

  const onExpire = () => {
    setIsDisabled(true)
  }

  const onFinish = (values) => {
    let {
      email,
      password,
      organization,
      full_name,
      username
    } = values

    email = email.trim()
    password = password.trim()
    full_name = full_name.trim()
    username = username.trim()
    if (organization) {
      organization = organization.trim()
    }
    
    const body = {
      email,
      password,
      organization,
      full_name,
      username
    }

    if (!organization) {
      delete body.organization
    }

    setIsLoading(true)

    api.registration(body)
      .then(() => {
        history.push({
          pathname: '/verification-email',
          state: {
            from: location.pathname,
            userForm: body
          }
        })
      })
      .catch(err => {
        const { status } = err.response
        const message = err.response.data.messages.length ? err.response.data.messages[0].message : null

        setIsLoading(false)

        if (status === 400 && message === 'Validation error') {
          form.setFields([{
            name: 'email',
            errors: ['Not a valid email']
          }])
        } else if (status === 400 && message === 'This email already used') {
          form.setFields([{
            name: 'email',
            errors: ['This email already used']
          }])
        } else if (status === 500) {
          message.error('Server error', 5)
        }
      })
  }

  const validateConfirmPassword = async (_, value) => {
    const newPassword = form.getFieldValue('password')

    if (value && value !== newPassword) {
      throw new Error('Password do not match')
    }
  }

  return (
    <section className="sign-up">
      { isAuth && <Redirect to='/' /> }
      
      <h2 className="caption-primary">Sign up for free</h2>
      <p>You'll be creating your next city-scale AR experience in minutes.</p>
      <Form
        form={ form }
        onFinish={ onFinish } 
        layout="vertical"
      >
        <Row gutter={ 16 }>
          <Col xs={ 24 } sm={ 12 }>
            <Form.Item
              name="username" 
              label="User Name"
              rules={[{ required: true, message: 'Please input your user name!' }]}
            >
              <Input suffix={ <UserOutlined /> } />
            </Form.Item>
          </Col>
          <Col xs={ 24 } sm={ 12 }>
            <Form.Item 
              name="full_name"
              label="Full Name"
              rules={[
                { required: true, message: 'Please input your full name!' },
                { min: 2, message: 'Must be at least 3 characters!' }
              ]}
            >
              <Input suffix={ <UserOutlined /> } />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={ 16 }>
          <Col xs={ 24 } sm={ 12 }>
            <Form.Item
              name="email" 
              label="Email"
              rules={[
                { required: true, message: 'Please input your email!' }
              ]}
            >
              <Input ref={ emailInput } suffix={ <MailOutlined /> } />
            </Form.Item>
          </Col>
          <Col xs={ 24 } sm={ 12 }>
            <Form.Item
              name="organization" 
              label="Organization (Optional)"
              rules={[
                { min: 2, message: 'Must be at least 2 characters!' }
              ]}
            >
              <Input suffix={ <BankOutlined /> } />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={ 16 }>
          <Col xs={ 24 } sm={ 12 }>
            <Form.Item
              name="password" 
              label="Password"
              rules={[
                { required: true, message: 'Please input your password!' },
                { min: 8, message: 'Must be at least 8 characters!' }
              ]}
            >
              <Input type="password" suffix={ <LockOutlined /> } />
            </Form.Item>
          </Col>
          <Col xs={ 24 } sm={ 12 }>
            <Form.Item 
              name="confirmPassword"
              label="Confirm Password"
              rules={[
                { required: true, message: 'Please confirm your password!' },
                { validator: validateConfirmPassword, message: `Passwords don't match!` }
              ]}
            >
              <Input type="password" suffix={ <LockOutlined /> } />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="agreement"
          valuePropName="checked"
          rules={[
            { validator:(_, value) => value ? Promise.resolve() : Promise.reject('Should accept agreement') },
          ]}
        >
          <Checkbox>
            I agree to Sturfee's <a className="link-primary" href="https://sturfee.com/terms" target="_blank">
            terms of service</a> and <Link className="link-primary" to="">
            privacy policy</Link>
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Reaptcha 
            sitekey={ reCaptchaKey } 
            onVerify={ onVerify } 
            onExpire={ onExpire }
          />
        </Form.Item>
        <Form.Item>
          <Button
            loading={ isLoading }
            disabled={ isDisabled }
            type="primary" 
            shape="round" 
            className="button-primary"
            htmlType="submit"
          >
            Create account
          </Button>
        </Form.Item>
      </Form>
    </section>
  )
}

export default SignUp