import React from 'react'
import { Redirect, useLocation, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import api from '../../../api'
import { deleteFromLocalStorage } from '../../../helpers'

// Actions
import { isAuth } from '../../login'

import { Button, message } from 'antd'

const CheckEmailNotification = () => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const prevLocation = location.state ? location.state.from : null
  const userForm = prevLocation ? location.state.userForm : null
  const email = prevLocation ? location.state.userForm.email : null

  const handleClick = () => {
    let data

    if (prevLocation === '/sign-up') {
      data = {
        email
      }
    } else {
      data = {
        newEmail: email
      }
    }

    api.resendVerification(data)
      .then(() => {
        message.success('Email has been resend successfully', 5)
      })
      .catch((err) => {
        const { status } = err.response
        const messageErr = err.response.data.messages.length ? err.response.data.messages[0].message : null

        if (status === 400 && messageErr === 'You already asked confirmation email resend recently') {
          message.error('You already asked confirmation email resend recently', 5)
        } else {
          message.error('Email was not sent', 5)
        }
      })
  }

  const handleLink = () => {
    if (!prevLocation) {
      return
    } else if (prevLocation === '/sign-up') {
      history.push({
        pathname: '/sign-up',
        state: {
          from: location.pathname,
          userForm
        }
      })
    } else {
      history.push({
        pathname: '/reenter-email'
      })
    }
  }

  const handleOut = () => {
    deleteFromLocalStorage('token')
    dispatch(isAuth(false))

    history.push('/login')
  }

  return (
    <section className="signup-notification">
      { !prevLocation && <Redirect to="/" /> }
      
      <h2 className="caption-primary">Check your email</h2>
      <p>
        An email with verification instructions was sent to <b>{ email }</b>. If you don’t see it in your inbox,
        please check your spam folder. Once your verify your email, you can continue to the developer portal.
      </p>
      <p>
        Didn't receive the email? 
        <Button 
          onClick={ handleClick }
          type="link" 
          className="link-primary"
        >
          Resend verification email
        </Button>
      </p>
      <p>
        Entered the wrong email? 
        <Button 
          onClick={ handleLink } 
          type="link" 
          className="link-primary"
        >
          Reenter your email
        </Button>
      </p>
      {
        prevLocation !== '/sign-up' && (
          <p>
            Want to sign in to another account? 
            <Button 
              onClick={ handleOut } 
              type="link"
               className="link-primary"
              >
                Log out
              </Button>
          </p>
        )
      }
    </section>
  )
}

export default CheckEmailNotification