import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { getTokens } from '../../../selectors'

import EditableToken from './EditableToken'
import { Button } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import beforeStart from '../../../assets/images/before-start.png'

const BeforeStart = () => {
  const tokens = useSelector(getTokens)
  const history = useHistory()

  const handleClick = () => {
    history.push('/quick-start/create-token')
  }

  return (
    <article className="before-start">
      <h2 className="caption-primary">Before You Start</h2>
      <section>
        <h3 className="caption-secondary">Prerequisites</h3>
        <ul>
          <li>Unity 2018.2.10f1</li>
          <li>Unity Android Module <b>(even if you are not building to Android)</b></li>
          <li>Unity iOS Module <b>(even if you are not building to iOS)</b></li>
        </ul>
        <p>
          <img 
            src={ beforeStart } 
            className="responsive-image"
            alt="picture" 
          />
        </p>
      </section>
      <section>
        <h3 className="caption-secondary">Download SDK & Generate Token</h3>
        <p>
          Before going through the get started guide, you will need to download 
          the Unity SDK package and create an access token.
        </p>
        <p>
          <Button
            type="primary" 
            shape="round" 
            className="button-primary"
            icon={ <DownloadOutlined /> } 
          >
            Download Unity SDK
          </Button>
        </p>
        <p>
          Let's create your first token. Each unique app should use its own 
          token. You can track your projects and tokens under <Link to="/sdk" 
          className="link-primary">SDK & Access Token</Link>.
        </p>
        {
          tokens.length ? (
          <EditableToken token={ tokens[0] } /> ) : (
          <p>
            <Button
              onClick={ handleClick }
              type="primary" 
              shape="round" 
              className="button-primary"
            >
              Create A Token
            </Button>
          </p>)
        }
      </section>
    </article>
  )
}

export default BeforeStart