import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { Header } from '../header'
import { ScrollToTop } from '../../components/scroll-to-top'
import { Home } from '../../pages/home'
import { EmailNotification } from '../../pages/email-notification'
import { EmailConfirm } from '../../pages/email-confirm'
import { PasswordRestore } from '../../pages/password-restore'
import { PasswordReset } from '../../pages/password-reset'
import { Login } from '../../pages/login'
import { SignUp } from '../../pages/sign-up'
import { CheckEmailNotification } from '../../pages/check-email-notification'
import { ChangeEmailConfirm } from '../../pages/change-email-confirm'
import { ReenterEmailForm } from '../../pages/reenter-email'

import 'antd/dist/antd.css'

const App = () => {
  return (
    <div className="app">
      <Header />
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/sign-up">
          <SignUp />
        </Route>
        <Route path="/verification-email">
          <CheckEmailNotification />
        </Route>
        <Route path="/reenter-email">
          <ReenterEmailForm />
        </Route>
        <Route path="/password-reset">
          <PasswordReset />
        </Route>
        <Route path="/restore-password/:md5">
          <PasswordRestore />
        </Route>
        <Route path="/check-email">
          <EmailNotification />
        </Route>
        <Route path="/email-confirm/:md5">
          <EmailConfirm />
        </Route>
        <Route path="/change-email-confirm/:md5">
          <ChangeEmailConfirm />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
      <ScrollToTop />
    </div>
  )
}

export default App
