import { Welcome } from './pages/welcome'
import { QuickStart } from './pages/quick-start'
import { CoverageMap } from './pages/coverage-map'
import { Sdk } from './pages/sdk'
import { Documentation } from './pages/documentation'
import { SampleApps } from './pages/sample-apps'
import { CompatibleDevices } from './pages/compatible-devices'
import { Analytics } from './pages/analytics'
import { Password } from './pages/password'
import { Profile } from './pages/profile'
import { CreateToken } from './pages/create-token'
import { TokenForm } from './components/token-form'

const routes = [
  {
    path: '/welcome',
    exact: true,
    component: Welcome
  },
  {
    path: '/quick-start',
    exact: true,
    component: QuickStart
  },
  {
    path: [
      '/quick-start/create-token',
      '/quick-start/edit-token',
      '/sdk/create-token',
      '/sdk/edit-token'
    ],
    exact: true,
    component: TokenForm
  },
  {
    path: '/coverage-map',
    exact: true,
    component: CoverageMap
  },
  {
    path: '/sdk',
    exact: true,
    component: Sdk
  },
  {
    path: '/documentation',
    exact: true,
    component: Documentation
  },
  {
    path: '/sample-apps',
    exact: true,
    component: SampleApps
  },
  {
    path: '/compatible-devices',
    exact: true,
    component: CompatibleDevices
  },
  {
    path: '/analytics',
    exact: true,
    component: Analytics
  },
  {
    path: '/settings/profile',
    exact: true,
    component: Profile
  },
  {
    path: '/settings/password',
    exact: true,
    component: Password
  },
]

export default routes