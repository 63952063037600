import React, { useState, useEffect } from 'react'
import { useLocation, useHistory, Redirect } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import api from '../../api'
import { getTokens } from '../../pages/sdk'

import { 
  Input, 
  Button, 
  message,
  Radio
} from 'antd'

const TokenForm = () => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [isEdit] = useState(() => {
    const { pathname } = location

    return pathname.includes('edit')
  })
  const [tokenName, setTokenName] = useState(() => {
    if (location.state && location.state.token) {
      return location.state.token.name
    } else {
      return ''
    }
  })
  const [region, setRegion] = useState(() => {
    if (location.state && location.state.token) {
      return location.state.token.region
    } else {
      return 'Not_Japan'
    }
  })

  const handleChangeName = e => {
    setTokenName(e.target.value)
  }

  const handleChangeRegion = e => {
    setRegion(e.target.value)
  }

  const handleCancel = () => {
    history.goBack()
  }

  const handleCreate = () => {
    const data = {
      region,
      name: tokenName,
      description: 'demo'
    }

    setIsLoading(true)
    
    api.createToken(data)
      .then(() => {
        message.success('Token has been created successfully!', 3)
        dispatch(getTokens())

        goBack()
      })
      .catch(() => {
        message.error('Token has not created!', 3)

        setIsLoading(false)
      })
  }

  const handleSave = () => {
    const { id: tokenId } = location.state.token
    const name = tokenName.trim()

    setIsLoading(true)

    const data = {
      name,
      tokenId,
      description: 'sadasd'
    }

    api.updateToken(data)
      .then(() => {
        message.success('Token name has been changed!', 3)
        dispatch(getTokens())

        goBack()
      })
      .catch(() => {
        message.error('Token name has not changed!', 3)
        setIsLoading(false)
      })
  }

  const goBack = () => {
    const pathname = location.pathname
    
    if (pathname.includes('sdk')) {
      history.push('/sdk')
    } else if (pathname.includes('quick-start')) {
      history.push('/quick-start')
    }
  }

  return (
    <section className="create-token">
      {/* When edit page openning in a new window, redirect to sdk page */}
      {
        isEdit && !location.state && <Redirect to='/sdk' />
      }

      <h2 className="caption-primary">{ isEdit ? 'Edit' : 'Create' } A Token</h2>
      <article>
        <h3 className="caption-secondary">Token name</h3>
        <p>Choose a name for your project.</p>
        <Input 
          value={ tokenName }
          onChange={ handleChangeName }
        />
      </article>


      <article>
        <h3 className="caption-secondary">Region (This section cannot be changed after token creation)</h3>
        <p>Will this project be used in Japan</p>
        <Radio.Group 
          onChange={ handleChangeRegion } 
          value={ region }
          disabled={ isEdit }
        >
          <Radio value='Not_Japan'>
            No: This project will work within our coverage <span className="underline">outside</span> of Japan
          </Radio>
          <Radio value='Japan'>
            Yes: This project will work within our coverage <span className="underline">inside</span> of Japan
          </Radio>
        </Radio.Group>
      </article>

      <p>
        If your project operates both inside and outside of Japan, you will
        need to create two unique tokens.
      </p>
      <p>
        <Button
          onClick={ handleCancel }
          type="primary" 
          shape="round" 
          className="button-secondary"
        >
          Cancel
        </Button>
        {
          isEdit ? (
            <Button
              onClick={ handleSave }
              disabled={ !tokenName }
              loading={ isLoading }
              type="primary" 
              shape="round" 
              className="button-primary"
            >
              Save
            </Button>) : (
            <Button
              onClick={ handleCreate }
              loading={ isLoading }
              disabled={ !tokenName }
              type="primary" 
              shape="round" 
              className="button-primary"
            >
              Create A Token
            </Button>
            ) 
        }
      </p>
    </section>
  )
}

export default TokenForm