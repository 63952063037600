import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import api from '../../../api'
import { getUserUuid, getUserEmail } from '../../../selectors'

// Actions
import { emailForResetPassword } from '../../password-reset'

import { Form, Input, Button, message } from 'antd'

const Password = () => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const uuid = useSelector(getUserUuid)
  const email = useSelector(getUserEmail)
  const [form] = Form.useForm()

  const onFinish = values => {
    const {
      currentPassword,
      newPassword: password
    } = values

    const data = {
      currentPassword,
      password
    }

    setIsLoading(true)

    api.changeUser(uuid, data)
      .then(res => {
        // Show success message
        message.success('Password has been changed successfully!', 5)
        form.resetFields()
      })
      .catch(err => {
        // Show valid error
        form.setFields([
          {
            name: 'currentPassword',
            errors: ['Invalid password']
          }
        ])
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const validateConfirmPassword = async (_, value) => {
    const newPassword = form.getFieldValue('newPassword')

    if (value && value !== newPassword) {
      throw new Error('Passwords do not match')
    }
  }

  const handleClick = () => {
    dispatch(emailForResetPassword(email))

    const data = {
      email
    }

    api.passwordRestoration(data)
      .then(() => {
        history.push('/check-email')
      })
      .catch(err => {
        const { status } = err.response
        const messageErr = err.response.data.messages[0].message

        if (status === 400 && messageErr === 'You already asked password restoration recently') {
          message.error('You already asked password restoration recently', 5)
        }
      })
  }
  
  return (
    <section className="password">
      <h2 className="caption-primary">Settings</h2>
      <h3 className="caption-secondary">Password</h3>
      <Form
        form={ form }
        onFinish={ onFinish }  
        layout="vertical"
      >
        <Form.Item
          name="currentPassword" 
          label="Current Password"
          rules={[{ required: true, message: 'Please input your current password!' }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="newPassword" 
          label="New Password"
          rules={[
            { required: true, message: 'Please input your new password!' },
            { min: 8, message: 'Password must be at least 8 characters!' }
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item 
          name="confirmPassword"
          label="Confirm New Password"
          rules={[
            { required: true, message: 'Please confirm your new password!' },
            { validator: validateConfirmPassword, message: `Passwords don't match` }
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item shouldUpdate={ true }>
          {
            () => (
              <div 
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
              <Button
                loading={ isLoading }
                disabled={
                  !form.getFieldValue('currentPassword') || 
                  !form.getFieldValue('newPassword') ||
                  !form.getFieldValue('confirmPassword') ||
                  form.getFieldsError().filter(({ errors }) => errors.length).length
                }
                htmlType="submit"
                type="primary" 
                shape="round" 
                className="button-primary"
              >
                Save
              </Button>
              <a
                onClick={ handleClick }
                className="link-primary"
              >
                Forgot password?
              </a>
              </div>
            )
          }
        </Form.Item>
      </Form>
    </section>
  )
}

export default Password