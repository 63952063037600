import { createAction } from 'redux-actions'
import types from './actionTypes'
import api from '../../api'

export const emailForResetPassword = createAction(types.EMAIL_FOR_RESET_PASSWORD)

/* Thunk */
// export const passwordRecovery = value => dispatch => {
//   api.passwordRestoration(value)
// }

