import types from './actionTypes'

const initialState = {
  tokenName: '',
  region: 'Not_Japan'
}

const createTokenForm = ( state = initialState, action ) => {
  switch (action.type) {
    case types.TOKEN_NAME:
      return { 
        ...state, 
        tokenName: action.payload 
      }
    case types.REGION:
      return {
        ...state,
        region: action.payload
      }
    default:
      return state
  }
}

export default createTokenForm