import types from './actionTypes'

const initialState = {
  email: ''
}

const reducer = ( state = initialState, action ) => {
  switch (action.type) {
    case types.EMAIL_FOR_RESET_PASSWORD:
      return {
        email: action.payload
      }
    default: 
      return state
  }
}

export default reducer