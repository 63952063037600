import { createAction } from 'redux-actions'
import types from './actionTypes'

import api from '../../api'
import { apiClient, apiToken } from '../../api/axios-instance'
import { saveToLocalStorage } from '../../helpers'

/* Action creators */
export const isAuth = createAction(types.IS_AUTH)

/* Thunk */
export const login = userData => dispatch => {
   return api.login(userData)
    .then(res => {
      const { token } = res.data

        apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`
        apiToken.defaults.headers.common['Authorization'] = `Bearer ${token}`
        
        saveToLocalStorage('token', token)
        dispatch(isAuth(true))
    })
} 