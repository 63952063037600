import axios from 'axios'

const BASE_API_URL = 'https://dev.developer.devsturfee.com/1.0.0'

const apiToken = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    post: {
      'Content-Type': 'application/json'
    } 
  }
})

apiToken.interceptors.request.use((config) => {
  config.params = config.params || {}
  config.params['devUserAPIURL'] = 'https://demo-sturfeeapi.zimalab.com/api/v1/user'

  return config
})

const tokenStr = localStorage.getItem('token')
const token = JSON.parse(tokenStr)

if (token) {
  apiToken.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

export default apiToken