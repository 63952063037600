import React, { useState } from 'react'
import { Redirect, useLocation, useHistory } from 'react-router-dom'

import api from '../../../api'
import { Form, Input, Button, message } from 'antd'
import { MailOutlined } from '@ant-design/icons'

const ReenterEmailForm = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [form] = Form.useForm()
  const location = useLocation()
  const history = useHistory()

  const token = localStorage.getItem('token')
 
  const onFinish = values => {
    let { email } = values
    email = email.trim()

    setIsLoading(true)

    const data = {
      email
    }

    api.reenterEmail(data)
      .then(() => {
        history.push({
          pathname: '/verification-email',
          state: {
            from: location.pathname,
            userForm: {
              email
            }
          }
        })
      })
      .catch(err => {
        const { status } = err.response
        const messageErr = err.response.data.messages && err.response.data.messages.length && err.response.data.messages[0].message

        if (status === 400 && messageErr === 'This email already used') {
          form.setFields([{
            name: 'email',
            errors: ['This email already used']
          }])
        } else {
          form.setFields([{
            name: 'email',
            errors: ['Not a valid email']
          }])
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <section className="reenter-email">
      { !token && <Redirect to='/login' /> }

      <h2 className="caption-primary">Reenter Email</h2>
      <p>Enter your email and we'll send you a link with verification instructions</p>
      <Form
        form={ form }
        onFinish={ onFinish }  
        layout="vertical"
        className="app-form"
      >
        <Form.Item 
          name="email"
          label="Email"
          rules={[
            { required: true, message: 'Please input your email!' },
          ]}
        >
          <Input suffix={ <MailOutlined /> } />
        </Form.Item>
        <Form.Item>
          <Button 
            loading={ isLoading }
            htmlType="submit"
            type="primary" 
            shape="round" 
            className="button-primary"
          >
            Send
          </Button>
        </Form.Item>
      </Form>
    </section>
  )
}

export default ReenterEmailForm