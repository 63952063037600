import types from './actionTypes'

const token = localStorage.getItem('token')

const initialState = token ? true : false

const isAuthenticated = (state = initialState, action) => {
  switch (action.type) {
    case types.IS_AUTH:
      return action.payload
    default: 
      return state
  }
}

export default isAuthenticated