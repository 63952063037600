import React from 'react'
import { Link } from 'react-router-dom'

const NextStep = () => {

  return (
    <article>
      <h2 className="caption-primary">What's Next</h2>
      <p>
        Congratulations on building your first world-scale AR app! Be sure to check
        out API reference <Link className="link-primary" to="">page</Link> before blazing your own path. You can also browse
        through the full SDK documentation <Link className="link-primary" to="/documentation">here</Link>.
      </p>
    </article>
  )
}

export default NextStep