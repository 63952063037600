import React from 'react'

import arCore from '../../../../assets/images/steps/ar-selection/ar-core.png'
import arKit from '../../../../assets/images/steps/ar-selection/ar-kit.png'

const ArSelection = () => (
  <div>
    <section style={{ marginBottom: '20px' }}>
      <h3 className="caption-secondary">Setting up ArKit *</h3>
      <p>
        <img 
          src={ arKit }
          className="responsive-image" 
          alt="ar kit" 
        />
      </p>
      <ul>
        <li>
          Find the <code className="code-text">SturfeeXRSession</code>  object 
          in the scene and in the Provider Set dropdown in Inspector 
          select IOS/ArKit Provider Set.
        </li>
        <li>
          Add LocalizationManager prefab from Assets/Sturfee/SDK/Localization 
          to your scene. This will take care of all aspects of localization, 
          including the UI, calls to the sturfee VPS server, and event handling.
        </li>
        <li>
          Add SturfeeUIManager prefab from Assets/Sturfee/Resources/UI/SturfeeUI 
          which provides helpful UI functionality outside of localization.
        </li>
      </ul>
    </section>
    <section style={{ marginBottom: '20px' }}>
      <h3 className="caption-secondary">Setting up ArCore</h3>
      <p>
        <img 
          src={ arCore } 
          className="responsive-image"
          alt="ar core" 
        />
      </p>
      <ul>
        <li>
          Go to <code className="code-text">Edit->Project Settings->Player 
          Settings</code> to open the Player Setting inspector window.
        </li>
        <li>
          Scroll down to <code className="code-text">XR Settings</code> section 
          and check <code className="code-text">ArCore Supported</code>. 
          Remember to uncheck if not working with ArCore.
        </li>
        <li>
          Find the <code className="code-text">SturfeeXRSession</code> object 
          in the scene and in the <code className="code-text">Provider 
          Set</code> dropdown in Inspector window, select 
          <code className="code-text">Android/ArCore Provider Set</code>.
        </li>
        <li>
          Add LocalizationManager prefab from Assets/Sturfee/SDK/Localization 
          to your scene. This will take care of all aspects of localization, 
          including the UI, calls to the sturfee VPS server, and event handling.
        </li>
      </ul>
    </section>
  </div>
)

export default ArSelection