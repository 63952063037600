import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import api from '../../../api'

import { Form, Input, Button, message } from 'antd'

const PasswordRestore = () => {
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const { md5 } = useParams()
  const [form] = Form.useForm()

  const onFinish = values => {
    const data = {
      password: values.newPassword
    }

    setIsLoading(true)

    api.restorePassword(md5, data)
      .then(() => {
        message.success('Password has been changed successfully!', 5)
        history.push('/')
      })
      .catch(() => {
        setIsLoading(false)
      })
  }

  const validateConfirmPassword = async (_, value) => {
    const newPassword = form.getFieldValue('newPassword')

    if (value && value !== newPassword) {
      throw new Error('Password do not match')
    }
  }

  return (
    <section className='password-restore'>
      <h2 className="caption-primary">Restore password</h2>
      <Form
        form={ form }
        onFinish={ onFinish }  
        layout="vertical"
      >
        <Form.Item
          name="newPassword" 
          label="New Password"
          rules={[
            { required: true, message: 'Please input your new password!' },
            { min: 8, message: 'Password must be at least 8 characters!' }
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item 
          name="confirmPassword"
          label="Confirm New Password"
          rules={[
            { required: true, message: 'Please confirm your new password!' },
            { validator: validateConfirmPassword, message: `Passwords don't match` }
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button
            loading={ isLoading }
            htmlType="submit"
            type="primary" 
            shape="round" 
            className="button-primary"
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </section>
  )
} 

export default PasswordRestore