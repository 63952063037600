import { createActions } from 'redux-actions'
import types from './actionTypes'
import api from '../../api'

const {
  TOKEN_NAME,
  REGION
} = types

export const { 
  tokenName, 
  region
} = createActions(TOKEN_NAME, REGION)

/* Thunk */
export const createToken = () => (_, getState) => {
  const {
    tokenName: name,
    region
  } = getState().createTokenForm


  const data = {
    description: 'demo',
    name,
    region
  }

  return api.createToken(data)
}



