import React from 'react'
import { Link } from 'react-router-dom'

const CoverageMap = () => {

  return (
    <section>
      <h2 className="caption-primary">Coverage Map</h2>
      <p>
        Below is our current VPS coverage. Please send us an <Link className="link-primary" to="">
          email</Link> if there is some where in particular you would like us to cover.
      </p>
    </section>
  )
}

export default CoverageMap