import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import api from '../../../api'
import { emailForResetPassword } from '../actions'

import { Form, Input, Button } from 'antd'
import { MailOutlined } from '@ant-design/icons'

const PasswordReset = () => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const history = useHistory()

  const onFinish = values => {
    setIsLoading(true)

    api.passwordRestoration(values)
      .then(() => {
        dispatch(emailForResetPassword(values.email))
        history.push('/check-email')
      })
      .catch(err => {
        const { status } = err.response
        
        setIsLoading(false)

        if (status === 404) {
          form.setFields([
            {
              name: 'email',
              errors: ['Email not found!']
            }
          ])
        } else if (status === 400) {
          form.setFields([
            {
              name: 'email',
              errors: ['You already asked password restoration recently!']
            }
          ])
        }
      })
  }

  return (
    <section className="password-reset">
      <h2 className="caption-primary">Reset password</h2>
      <p>Enter your email and we'll send you a link to reset your password</p>
      <Form
        form={ form }
        onFinish={ onFinish }  
        layout="vertical"
        className="app-form"
      >
        <Form.Item 
          name="email"
          label="Email"
          rules={[
            { required: true, message: 'Please input your email!' },
            { type:'email', message: 'Incorrect email!' }
          ]}
        >
          <Input suffix={ <MailOutlined /> } />
        </Form.Item>
        <Form.Item>
          <Button 
            loading={ isLoading }
            htmlType="submit"
            type="primary" 
            shape="round" 
            className="button-primary"
          >
            Send
          </Button>
        </Form.Item>
      </Form>
    </section>
  )
}

export default PasswordReset