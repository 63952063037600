import types from './actionTypes'

const initialState = {
  username: '',
  fullname: '',
  email: '',
  organization: '',
  password: ''
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.USERNAME:
      return {
        ...state,
        username: action.payload
      }
    case types.FULLNAME:
      return {
        ...state,
        fullname: action.payload
      }
    case types.EMAIL:
      return {
        ...state,
        email: action.payload
      }
    case types.ORGANIZATION:
      return {
        ...state,
        organization: action.payload
      }
    case types.PASSWORD:
      return {
        ...state,
        password: action.payload
      }
    default: 
      return state
  }
}

export default reducer