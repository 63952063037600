import { createAction } from 'redux-actions'
import types from './actionTypes'
import api from '../../api'
// import actions from profile module
import { 
  userFullname,
  userEmail,
  userOrganization,
  userUuid,
  userName
 } from '../../pages/profile'

export const mainPageIsLoading = createAction(types.MAIN_PAGE_IS_LOADING)

/* Thunk */
export const getUser = () => dispatch => {
  dispatch(mainPageIsLoading(true))

  return api.getUser()
    .then(res => {
      const {
        fullName,
        email,
        organization,
        uuid,
        username
      } = res.data.result

      dispatch(userFullname(fullName))
      dispatch(userEmail(email))
      dispatch(userOrganization(organization))
      dispatch(userUuid(uuid))
      dispatch(userName(username))

      dispatch(mainPageIsLoading(false))
    })
}



