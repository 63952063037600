import React from 'react'

import menuConfigure from '../../../../assets/images/steps/sdk-setup/menu-configure.png'
import pasteToken from '../../../../assets/images/steps/sdk-setup/paste-token.png'
import editorWindow from '../../../../assets/images/steps/sdk-setup/editor-window.png'

const SdkSetup = () => (
  <ul>
    <li>
      <p>
        If it is the first time you are using this SDK then you can skip 
        this step and start from step 2.
      </p>
      <pre> 
        <code>
          {/* {
    `
    Before installing the package : 

    1. Delete folder GoogleARCore
    2. Delete folder Sturfee
    3. Delete folder UnityArKitPlugin
    4. Remove layers 'sturfeeBuillding', 'sturfeeTerrain' and 'sturfeeBackground' by selecting "Edit Layers" from 'Layers' tab located top-right in Unity Editor

    After Installing the package (after step 3 below)

    1. If you have a scene outside Assets/Sturfee folder and that scene has 'SturfeeXrSession' prefab, then delete that GameObject(don't delete prefab) and add it again from Assets/Sturfee/SDK
    2. Go to Player Settings --> Other Settings and update 'Scripting Runtime Version' from  .NET 3.5 to .NET 4.x
    `
          } */}
          <p>Before installing the package : </p>
          <ul>
            <li>Delete folder GoogleARCore</li>
            <li>Delete folder Sturfee</li>
            <li>Delete folder UnityArKitPlugin</li>
            <li>Remove layers 'sturfeeBuillding', 'sturfeeTerrain' and 'sturfeeBackground' by selecting "Edit Layers" from 'Layers' tab located top-right in Unity Editor</li>
          </ul>
          <p>After Installing the package (after step 3 below)</p>
          <ul>
            <li>If you have a scene outside Assets/Sturfee folder and that scene has 'SturfeeXrSession' prefab, then delete that GameObject(don't delete prefab) and add it again from Assets/Sturfee/SDK</li>
            <li>Go to Player Settings --> Other Settings and update 'Scripting Runtime Version' from  .NET 3.5 to .NET 4.x</li>
          </ul>
        </code>
      </pre>
    </li>
    <li>
      <p>
        Download <code className="code-text">Sturfee-VPS-SDK_v1.4.3.unitypackage
        </code> provided by Sturfee.
      </p>
    </li>
    <li>
      <p>
        In Unity, go to <code className="code-text">Assets->Import Package->Custom 
        Package...</code> and navigate to the file you downloaded in step 2.
      </p>
    </li>
    <li>
      <p>
        After the package is imported, go to Sturfee->Configure in the 
        main menu.
      </p>
      <p>
        <img 
          src={ menuConfigure } 
          className="responsive-image" 
          alt="menu configure" 
        />
      </p>
    </li>
    <li>
      <p>
        Paste your access token that was provided by Sturfee and hit 
        "Request Access".
      </p>
      <p>
        <img 
          src={ pasteToken } 
          className="responsive-image"
          alt="paste token" 
        />
      </p>
    </li>
    <li>
      <p>
        This should unlock more options in the Sturfee Editor window. 
        Keep this window open for the next section.
      </p>
      <p>
        <img 
          src={ editorWindow } 
          className="responsive-image"
          alt="editor window" 
        />
      </p>
    </li>
  </ul>
)

export default SdkSetup