import types from './actionTypes'

const initialState = ''

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.EMAIL_VERIFICATION:
      return action.payload
    default:
      return state
  }
}

export default reducer