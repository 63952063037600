import React from 'react'

const Documentation = () => {

  return (
    <section>
      <h2 className="caption-primary">Documentation</h2>
    </section>
  )
}

export default Documentation