import React from 'react'

import configurationSection from '../../../../assets/images/steps/build-app/configuration-section.png'

const BuildApp = () => (
  <div>
    <section style={{ marginBottom: '20px' }}>
      <h3 className="caption-secondary">Android</h3>
      <ul>
        <li>
          Go to File->Build Settings and change your platform to Android
        </li>
        <li>
          Click the "Player Settings" button
        </li>
        <li>
          Find the "Other Settings" in the Editor window and edit the <b>
          Package Name</b> to your choice
        </li>
        <li>
          Save and Build the Project
        </li>
      </ul>
    </section>
    <section>
      <h3 className="caption-secondary">iOS *</h3>
      <ul>
        <li>
          Go to File->Build Settings and change your platform to iOS.
        </li>
        <li>
          Click the "Player Settings" button.
        </li>
        <li>
          Find the "Other Settings" in the Editor window and set the <b>
          Bundle Identifier</b> to your choice.
        </li>
        <li>
          <p>
            Make sure Camera and Location permissions are enabled (Player 
            Settings -> iOS tab -> Other Settings -> Configuration section).
          </p>
          <p>
            <img 
              src={ configurationSection } 
              className="responsive-image"
              alt="configuration section" 
            />
          </p>
        </li>
        <li>
          Save and Build the Project
        </li>
      </ul>
    </section>
  </div>
)

export default BuildApp