import React, { useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { login } from '../actions'
import { getIsAuth } from '../../../selectors'

import { Form, Input, Button, Alert } from 'antd'
import { MailOutlined } from '@ant-design/icons'

const Login = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [message, setMessage] = useState('')
  const isAuthenticated = useSelector(getIsAuth)
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const onFinish = userData => {
    setIsLoading(true)

    dispatch(login(userData))
      .catch(err => {
        const { status } = err.response

        switch (status) {
          case 401:
            setMessage('Email or password is incorrect.')
            break
          case 400:
            setMessage('Email is not confirmed')
            break
          default:
            setMessage('Error')
        }

        setIsError(true)
        setIsLoading(false)
      })
  }

  return (
    <div className="auth-container">
      { isAuthenticated && <Redirect to='/' /> }

      <h2 className="caption-primary">Developer Portal Log In</h2>
      <Form
        form={ form }
        onFinish={ onFinish } 
        layout="vertical"
      >
        <Form.Item 
          name="email"
          label="Email"
          rules={[
            { required: true, message: 'Please input your email!' }
          ]}
        >
          <Input suffix={ <MailOutlined /> } />
        </Form.Item>
        <Form.Item 
          name="password"
          label="Password"
          rules={[{ required: true, message: 'Please input your password!' }]} 
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Link
            to="/password-reset"
            className="link-primary"
          >
            Forgot password?
          </Link>
        </Form.Item>
        <Form.Item>
          {
            isError &&
            <Alert 
              message={ message } 
              type="error" 
              showIcon 
              closable
            />
          }
  
          <p>
            <Button
              loading={ isLoading }
              htmlType="submit"
              type="primary" 
              shape="round" 
              className="button-primary"
            >
              Log In
            </Button>
          </p>
          <p>
            <Link
              to="/sign-up"
              className="link-primary"
            >
              Don't have an account?
            </Link>
          </p>
        </Form.Item>
      </Form>
    </div>
  )
}

export default Login