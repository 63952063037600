import { createActions } from 'redux-actions'
import types from './actionTypes'
import api from '../../api'

/* Action creators */ 
export const {
  setTokens,
  setEditingTokenKey,
  toDelete,
  toEdit,
  tokensIsLoading
} = createActions(types.SET_TOKENS, 
  types.SET_EDITING_TOKEN_KEY,
  types.TO_DELETE,
  types.TO_EDIT,
  types.TOKENS_IS_LOADING
)

/* Thunk */
export const getTokens = () => dispatch => {
  dispatch(tokensIsLoading(true))

  api.getTokens()
    .then(res => {
      const tokens = res.data

      dispatch(tokensIsLoading(false))
      dispatch(setTokens(tokens))
    })
}

export const deleteToken = tokenId => dispatch => {

  const config = {
    params: {
      tokenId
    }
  }

  return api.revokeToken(null, config)
    .then(() => {
      dispatch(getTokens())
    })
}

export const updateToken = (tokenId, newData) => dispatch => {
  const data = {
    ...newData,
    tokenId,
    description: 'sadasd'
  }

  return api.updateToken(data)
    .then(() => {
      dispatch(getTokens())
    })
}