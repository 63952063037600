import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import api from '../../../api'
import { apiClient, apiToken } from '../../../api/axios-instance'
import { saveToLocalStorage } from '../../../helpers'

// Actions
import { isAuth } from '../../login'

const ChangeEmailConfirm = () => {
  const history = useHistory()
  const { md5 } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    api.emailConfirm(md5)
      .then((res) => {
        const { token } = res.data.result

        apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`
        apiToken.defaults.headers.common['Authorization'] = `Bearer ${token}`
        
        saveToLocalStorage('token', token)
        dispatch(isAuth(true))
      })
      .finally(() => {
        history.push('/settings/profile')
      })
  }, [])

  return null
}

export default ChangeEmailConfirm